import { Hyperlink } from 'ks-react-design-system'

import { TopUpActionType } from 'src/components/TopUpAction'
import { TopUpActionInfoItem } from 'src/components/TopUpOtherMethods'

type Content = {
  orderExtraMoney: TopUpActionType
  topUpWithNoCommission: TopUpActionType
  topUpOtherMethods: TopUpActionInfoItem
}

const content: Content = {
  orderExtraMoney: {
    title: 'Замовити Екстра гроші',
    description: "Отримайте від 20 ₴ до 500 ₴ на бонусний рахунок і користуйтеся зв'язком одразу.",
    buttonText: 'Побачити доступні суми',
  },
  topUpWithNoCommission: {
    title: 'Поповнити на сайті без комісії',
    description:
      'Навіть коли немає інтернету, ви зможете поповнитися тут через банківську картку та Google Pay.',
    buttonText: 'Поповнити',
    href: '/pay',
  },
  topUpOtherMethods: {
    title: 'Які ще є способи поповнитися',
    list: [
      {
        id: 1,
        node: (
          <>
            Через <Hyperlink href="https://mykyivstar.page.link/qQzQ">Мій Київстар</Hyperlink>.
          </>
        ),
      },
      {
        id: 2,
        node: (
          <>
            Надіслати повідомлення з проханням поповнити рахунок. Наберіть <b>*132*380ХХХХХХХХХ#</b>
            , де ХХХХХХХХХ — це номер іншого абонента Київстар.
          </>
        ),
      },
      {
        id: 3,
        node: (
          <>
            У найближчому{' '}
            <Hyperlink
              href="https://cdn.kyivstar.ua/sites/default/files/robota_magazyniv_kyyivstar.pdf"
              target="_blank"
            >
              магазині
            </Hyperlink>{' '}
            Київстар або готівкою через термінали самообслуговування.
          </>
        ),
      },
    ],
  },
}

export default content
